import script from "./App.vue?vue&type=script&setup=true&lang=js"
export * from "./App.vue?vue&type=script&setup=true&lang=js"

import "./App.vue?vue&type=style&index=0&id=51e3c5b2&lang=css"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerPie from 'quasar/src/components/spinner/QSpinnerPie.js';
import qInstall from "../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QPageContainer,QPage,QCircularProgress,QInnerLoading,QSpinnerPie});
