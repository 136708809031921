import { useRouter } from 'vue-router';
import BaseService from './base.service';
import { useAuthStore } from '../store/authStore';

class AdminService extends BaseService {
  async login(data) {
    const { signIn } = useAuthStore();

    const loginResult = await this.dao.login(data);
    const { code, payload } = loginResult;
    if (code === 0) {
      signIn(payload);
      return true;
    }
    return false;
  }

  async checklogin() {
    const { signIn, signOut } = useAuthStore();
    const router = useRouter();
    try {
      const checkLoginResult = await this.dao.checklogin();
      const { code, payload } = checkLoginResult;
      if (code === 0) {
        signIn(payload);
        if (payload.companyid === '000000000') {
          router.push({ path: '/solidnet/companies' });
        }
      }
    } catch (ex) {
      signOut();
    }
  }

  async logout() {
    const { signOut } = useAuthStore();
    try {
      const logoutResult = await this.dao.logout();
      const { code, payload } = logoutResult;
      if (code === 0) {
        signOut();
      }
      return true;
    } catch (ex) {
      return false;
    }
  }

  async findGroups(params) {
    const groupsResult = await this.dao.findGroups(params);
    const { code, payload } = groupsResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getListTag(params) {
    const tagResult = await this.dao.getListTag(params);
    const { code, payload } = tagResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getListTagName(params) {
    const tagNameResult = await this.dao.getListTagName(params);
    const { code, payload } = tagNameResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async addMember(data) {
    const addMemberResult = await this.dao.addMember(data);
    const { code, payload } = addMemberResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async updateUser(params, paramsId) {
    const updateUserResult = await this.dao.updateUser(params, paramsId);
    const { code, payload } = updateUserResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async getUserDetail(paramsId) {
    const getUserDetailResult = await this.dao.getUserDetail(paramsId);
    const { code, payload } = getUserDetailResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getDetailGroup(groupId) {
    const groupsDetailResult = await this.dao.findDetailGroup(groupId);
    const { code, payload } = groupsDetailResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async createGroup(data) {
    const createResult = await this.dao.createGroup(data);
    const { code, payload } = createResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async updateContent(id, data) {
    const updateContentResult = await this.dao.updateContent(id, data);
    const { code, payload } = updateContentResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async updateStatusContent(id) {
    const updateContentResult = await this.dao.updateStatusContent(id);
    const { code } = updateContentResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async createContent(data) {
    const createContentResult = await this.dao.createContent(data);
    const { code, payload } = createContentResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async pushNoticeContent(id, data) {
    const pushNoticeResult = await this.dao.pushNoticeContent(id, data);
    const { code } = pushNoticeResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async updateGroup(groupId, data) {
    const updateResult = await this.dao.updateGroup(groupId, data);
    const { code, payload } = updateResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async deleteGroup(groupId) {
    const updateResult = await this.dao.deleteGroup(groupId);
    const { code, payload } = updateResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async getListContent(params) {
    const listContent = await this.dao.getListContent(params);
    const { code, payload } = listContent;
    if (code === 0) {
      return payload;
    }
    return {};
  }

  async getDetailContent(contentId) {
    const contentDetailResult = await this.dao.getDetailContent(contentId);
    const { code, payload } = contentDetailResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async deleteContent(contentId) {
    const deleteResult = await this.dao.deleteContent(contentId);
    const { code } = deleteResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async getTag(id, type) {
    const tagResult = await this.dao.getTag(id, type);
    const { code, payload } = tagResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getCompany() {
    const company = await this.dao.getCompany();
    const { code, payload } = company;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async updateTag(id, type, data) {
    const tagResult = await this.dao.updateTag(id, type, data);
    const { code, payload } = tagResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async addTag(data) {
    const tagResult = await this.dao.addTag(data);
    const { code, payload } = tagResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async deleteTag(id, type) {
    const tagResult = await this.dao.deleteTag(id, type);
    const { code } = tagResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async profile() {
    const result = await this.dao.profile();
    const { code, payload } = result;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async forgetPassword(data) {
    const result = await this.dao.forgetPassword(data);
    const { code, payload } = result;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async getSetting() {
    const result = await this.dao.getSetting();
    const { code, payload } = result;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getListUser(params) {
    const getListUserDetail = await this.dao.getListUser(params);
    const { code, payload } = getListUserDetail;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async addMultipleUser(data, page) {
    const addMultipleUserDetail = await this.dao.addMultipleUser(data, page);
    const { code, payload } = addMultipleUserDetail;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async findNotices(params) {
    const noticesListResult = await this.dao.findNotices(params);
    const { code, payload } = noticesListResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getViewData(params) {
    const getViewDataResult = await this.dao.getViewData(params);
    const { code, payload } = getViewDataResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getDetailNotice(noticeId) {
    const noticeDetailResult = await this.dao.findDetailNotice(noticeId);
    const { code, payload } = noticeDetailResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getReportData(params) {
    const getReportDataResult = await this.dao.getReportData(params);
    const { code, payload } = getReportDataResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async deleteNotice(noticeId) {
    const deleteNoticeResult = await this.dao.deleteNotice(noticeId);
    const { code, payload } = deleteNoticeResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async addDiskSpaces(data) {
    const addDiskSpaceResult = await this.dao.addDiskSpaces(data);
    const { code, payload } = addDiskSpaceResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async createNotice(data) {
    const createNoticeResult = await this.dao.createNotice(data);
    const { code, payload } = createNoticeResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async updateNotice(noticeId, data) {
    const updateNoticeResult = await this.dao.updateNotice(noticeId, data);
    const { code, payload } = updateNoticeResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async pushOnNotice(noticeId) {
    const pushNoticeResult = await this.dao.pushOnNotice(noticeId);
    const { code, payload } = pushNoticeResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async getSuggestList() {
    const suggestList = await this.dao.getSuggestList();
    const { code, payload } = suggestList;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getSuggestFullnameList() {
    const suggestList = await this.dao.getSuggestFullnameList();
    const { code, payload } = suggestList;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getSuggestNamekanaList() {
    const suggestList = await this.dao.getSuggestNamekanaList();
    const { code, payload } = suggestList;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getDetailReport(paramsId) {
    const detailReportResult = await this.dao.getDetailReport(paramsId);
    const { code, payload } = detailReportResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async deleteReport(reportId) {
    const delReportResult = await this.dao.deleteReport(reportId);
    const { code } = delReportResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async delUser(paramsId) {
    const delUserResult = await this.dao.delUser(paramsId);
    const { code, payload } = delUserResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async saveSort(data) {
    const saveSortResult = await this.dao.saveSort(data);
    const { code, payload } = saveSortResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async completeUpload(data) {
    const uploadResult = await this.dao.completeUpload(data);
    const { code, payload } = uploadResult;
    if (code === 0) {
      return true;
    }
    return false;
  }
}

export default new AdminService('admin');
