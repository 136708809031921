import BaseRepository from './base.repository';

class AdminRepository extends BaseRepository {
  constructor() {
    super('/admin');
  }

  login(data) {
    return this.client.post('/login', data);
  }

  checklogin() {
    return this.client.get('/checklogin');
  }

  findGroups(query) {
    return this.client.get('/groups', query);
  }

  findDetailGroup(id) {
    return this.client.get(`/groups/${id}`);
  }

  createGroup(data) {
    return this.client.post('/groups', data);
  }

  updateGroup(id, data) {
    return this.client.put(`/groups/${id}`, data);
  }

  deleteGroup(id) {
    return this.client.delete(`/groups/${id}`);
  }

  addMember(params) {
    return this.client.post('/users', params);
  }

  getListTag(query) {
    return this.client.get('/tags', query);
  }

  getListTagName(query) {
    return this.client.get('/suggest/tag-name', query);
  }

  getListContent(query) {
    return this.client.get('/contents', query);
  }

  updateContent(id, data) {
    return this.client.put(`/contents/${id}`, data);
  }

  updateStatusContent(id) {
    return this.client.put(`/contents/created/${id}`);
  }

  createContent(data) {
    return this.client.post('/contents', data);
  }

  getDetailContent(id) {
    return this.client.get(`/contents/${id}`);
  }

  deleteContent(id) {
    return this.client.delete(`/contents/${id}`);
  }

  pushNoticeContent(id, data) {
    return this.client.post(`/contents/notice/${id}`, data);
  }

  getTag(id, type) {
    return this.client.get(`/tags/${type}/${id}`);
  }

  getCompany() {
    return this.client.get('/company');
  }

  updateTag(id, type, data) {
    return this.client.put(`/tags/${type}/${id}`, data);
  }

  addTag(data) {
    return this.client.post('/tags', data);
  }

  delUser(paramsId) {
    return this.client.delete(`/users/${paramsId}`);
  }

  deleteTag(id, type) {
    return this.client.delete(`/tags/${type}/${id}`);
  }

  profile() {
    return this.client.get('/company');
  }

  forgetPassword(data) {
    return this.client.put('/forget-password', data);
  }

  getSetting() {
    return this.client.get('/setting');
  }

  getListUser(query) {
    return this.client.get('/users', query);
  }

  addMultipleUser(data, page) {
    return this.client.post(`/users/import?page=${page}`, data);
  }

  findNotices(query) {
    return this.client.get('/notices', query);
  }

  findDetailNotice(id) {
    return this.client.get(`/notices/${id}`);
  }

  deleteNotice(id) {
    return this.client.delete(`/notices/${id}`);
  }

  createNotice(data) {
    return this.client.post('/notices', data);
  }

  updateNotice(id, data) {
    return this.client.put(`/notices/${id}`, data);
  }

  updateUser(params, paramsId) {
    return this.client.put(`/users/${paramsId}`, params);
  }

  pushOnNotice(id) {
    return this.client.post(`/notices/${id}`);
  }

  getViewData(query) {
    return this.client.get('/views', query);
  }

  getReportData(query) {
    return this.client.get('/reports', query);
  }

  getSuggestList() {
    return this.client.get('/suggest/contents-title');
  }

  getSuggestFullnameList() {
    return this.client.get('/suggest/users-fullname');
  }

  getSuggestNamekanaList() {
    return this.client.get('/suggest/users-kananame');
  }

  getDetailReport(id) {
    return this.client.get(`/reports/${id}`);
  }

  deleteReport(id) {
    return this.client.delete(`/reports/${id}`);
  }

  addDiskSpaces(data) {
    return this.client.post('/diskspaces', data);
  }

  getUserDetail(paramsId) {
    return this.client.get(`/users/${paramsId}`);
  }

  saveSort(data) {
    return this.client.put('/orders', data);
  }

  logout() {
    return this.client.post('/logout');
  }

  completeUpload(body) {
    return this.client.post('/complete-upload', body);
  }
}

export default new AdminRepository();
