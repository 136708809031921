const routes = [
  {
    path: '/',
    component: () => import('pages/Error404.vue'),
    children: [
    ],
  },
  {
    path: '/solidnet',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Companies/List.vue') },
      { path: 'companies', component: () => import('pages/Companies/List.vue') },
      { path: 'detailCompany/:id', component: () => import('pages/Companies/DetailCompany.vue') },
      { path: 'editCompany/:id', component: () => import('pages/Companies/EditCompany.vue') },
      { path: 'createCompany', component: () => import('pages/Companies/CreateCompany.vue') },
      { path: 'notices', component: () => import('pages/Notices/ListNotice.vue') },
      { path: 'notices/detail/:id', component: () => import('pages/Notices/DetailNotice.vue') },
      { path: 'notices/edit/:id', component: () => import('pages/Notices/EditAddNotice.vue') },
      { path: 'notices/add', component: () => import('pages/Notices/EditAddNotice.vue') },
      { path: 'setting', component: () => import('pages/Setting/SettingOther.vue') },
    ],
  },
  {
    path: '/admin',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Member/ListMember.vue') },
      { path: 'users', component: () => import('pages/Member/ListMember.vue') },
      { path: 'users/add', component: () => import('pages/Member/EditAddMember.vue') },
      { path: 'users/edit/:id', component: () => import('pages/Member/EditAddMember.vue') },
      { path: 'users/detail/:id', component: () => import('pages/Member/MemberDetail.vue') },
      { path: 'contents', component: () => import('pages/Contents/ListContent.vue') },
      { path: 'contentsVideo', component: () => import('pages/Contents/video/ListContentVideo.vue') },
      { path: 'contentsAudio', component: () => import('pages/Contents/audio/ListContentAudio.vue') },
      { path: 'contentsDocument', component: () => import('pages/Contents/document/ListContentDocument.vue') },
      { path: 'addVideo', component: () => import('pages/Contents/video/EditContentVideo.vue') },
      { path: 'addAudio', component: () => import('pages/Contents/audio/EditContentAudio.vue') },
      { path: 'addDocument', component: () => import('pages/Contents/document/EditContentDocument.vue') },
      { path: 'editVideo/:id', component: () => import('pages/Contents/video/EditContentVideo.vue') },
      { path: 'editAudio/:id', component: () => import('pages/Contents/audio/EditContentAudio.vue') },
      { path: 'editDocument/:id', component: () => import('pages/Contents/document/EditContentDocument.vue') },
      { path: 'detailVideo/:id', component: () => import('pages/Contents/video/DetailContentVideo.vue') },
      { path: 'detailAudio/:id', component: () => import('pages/Contents/audio/DetailContentAudio.vue') },
      { path: 'detailDocument/:id', component: () => import('pages/Contents/document/DetailContentDocument.vue') },
      { path: 'tags/tag1', component: () => import('src/pages/Tag/ListTag1.vue') },
      { path: 'tags/tag2', component: () => import('src/pages/Tag/ListTag2.vue') },
      { path: 'tags/detailTag/:type/:id', component: () => import('pages/Tag/DetailTag.vue') },
      { path: 'tags/addTag/:type', component: () => import('pages/Tag/DetailTag.vue') },
      { path: 'profile', component: () => import('pages/Profile/Profile.vue') },
      // { path: 'forgot-password', component: () => import('pages/Password/ForgetPassword.vue') },
      { path: 'groups', component: () => import('pages/Groups/List.vue') },
      { path: 'groups/add', component: () => import('pages/Groups/EditAddGroup.vue') },
      { path: 'groups/edit/:id', component: () => import('pages/Groups/EditAddGroup.vue') },
      { path: 'notices', component: () => import('pages/Notices/ListNotice.vue') },
      { path: 'notices/detail/:id', component: () => import('pages/Notices/DetailNotice.vue') },
      { path: 'notices/edit/:id', component: () => import('pages/Notices/EditAddNotice.vue') },
      { path: 'notices/add', component: () => import('pages/Notices/EditAddNotice.vue') },
      { path: 'views', component: () => import('pages/Views/ConfirmView.vue') },
      { path: 'reports', component: () => import('pages/Report/ConfirmReport.vue') },
      { path: 'reports/:id', component: () => import('pages/Report/ReportDetail.vue') },
      { path: 'addDiskspace', component: () => import('pages/AddDiskspace/Index.vue') },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
