import BaseRepository from './base.repository';

class SolidnetRepository extends BaseRepository {
  constructor() {
    super('/solidnet');
  }

  login(data) {
    return this.client.post('/login', data);
  }

  checklogin() {
    return this.client.get('/checklogin');
  }

  logout() {
    return this.client.post('/logout');
  }

  findCompany(query) {
    return this.client.get('/companies', query);
  }

  impersonationAdmin(data) {
    return this.client.post('/impersonation', data);
  }

  updateSetting(data) {
    return this.client.put('/setting', data);
  }

  cancelImpersonationAdmin() {
    return this.client.post('/cancelimpersonation');
  }

  getDetailCompany(id) {
    return this.client.get(`/companies/${id}`);
  }

  updateCompany(id, data) {
    return this.client.put(`/companies/${id}`, data);
  }

  createCompany(data) {
    return this.client.post('/companies', data);
  }

  findNotices(query) {
    return this.client.get('/notices', query);
  }

  findDetailNotice(id) {
    return this.client.get(`/notices/${id}`);
  }

  deleteNotice(id) {
    return this.client.delete(`/notices/${id}`);
  }

  createNotice(data) {
    return this.client.post('/notices', data);
  }

  updateNotice(id, data) {
    return this.client.put(`/notices/${id}`, data);
  }

  pushOnNotice(id) {
    return this.client.post(`/notices/${id}`);
  }

  deleteCompany(id) {
    return this.client.delete(`/companies/${id}`);
  }
}

export default new SolidnetRepository();
