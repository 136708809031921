import Axios from 'axios';
import { Notify } from 'quasar';
import ToastUtil from 'src/shared/utilities/toast';
import { useAppStore } from '../../../store/appStore';
import { useAuthStore } from '../../../store/authStore';

// merge params to axios config
function mergeAxiosConfig(data, config) {
  const axiosConfig = Object.assign(config || {}, {
    params: data,
  });
  return axiosConfig;
}

export default class RestClient {
  #client;

  #servicePath;

  constructor(servicePath) {
    this.client = this.createAxiosClient();
    this.servicePath = servicePath;
  }

  createAxiosClient() {
    const axiosInstance = Axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.X_API_KEY,
      },
      withCredentials: true,
    });

    axiosInstance.interceptors.request.use(this.onRequest, this.onRequestError);

    return axiosInstance;
  }

  onRequest = async (config) => {
    await this.extendTokenValidityPeriod();
    // use store when local
    // const { token } = useAuthStore();
    // if (config.headers) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }

    // do something here before request
    return config;
  };

  onRequestError(error) {
    return Promise.reject(error);
  }

  async extendTokenValidityPeriod() {
    // extend token here
  }

  request(method, url, data, config) {
    const { setLoading } = useAppStore();

    return new Promise((resolve) => {
      // show loading
      setLoading(true);
      const axiosConfig = Object.assign(config, {
        url: `${this.servicePath}${url}`,
        method,
        data,
      });

      this.client.request(axiosConfig).then((response) => {
        const result = response.data;
        // Error message normal
        if (![0, 401, 202, 203, 204].includes(result.code)) {
          ToastUtil.error(result.message);
        }
        // Error is message have button close
        if ([202].includes(result.code)) {
          Notify.create({
            type: 'negative',
            closeBtn: true,
            timeout: 0,
            message: result.message,
          });
        }
        // Error is message warning
        if ([203].includes(result.code)) {
          ToastUtil.warning(result.message);
        }
        // Error is message html
        if ([204].includes(result.code)) {
          Notify.create({
            type: 'negative',
            message: result.message,
            html: true,
          });
        }
        // Error logout
        if (result.code === 401) {
          const { signOut } = useAuthStore();
          signOut();
        }
        resolve(result);
      })
        .catch((error) => {
          if (Axios.isAxiosError(error)) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              if (error.response.data) {
                // error data from server
                const serviceErrorResponse = {
                  data: error.response.data,
                  statusCode: error.response.status,
                };
                resolve(serviceErrorResponse);
              } else {
                error.code = 'API_ERROR';
                resolve(error);
              }
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              resolve(error);
            } else {
              // Something happened in setting up the request that triggered an Error
              resolve(error);
            }
          } else {
            resolve(error);
          }
          ToastUtil.exception(error);
        }).finally(() => {
          // hide loading
          setLoading(false);
        });
    });
  }

  get(url, data, config) {
    // merge params to axios config
    const axiosConfig = mergeAxiosConfig(data, config);

    return this.request('GET', url, null, axiosConfig);
  }

  post(url, data, config) {
    // merge params to axios config
    const axiosConfig = mergeAxiosConfig(data, config);
    delete axiosConfig.params;
    return this.request('POST', url, data, axiosConfig);
  }

  put(url, data, config) {
    // merge params to axios config
    const axiosConfig = mergeAxiosConfig(data, config);
    delete axiosConfig.params;
    return this.request('PUT', url, data, axiosConfig);
  }

  delete(url, data, config) {
    // merge params to axios config
    const axiosConfig = mergeAxiosConfig(data, config);
    delete axiosConfig.params;
    return this.request('DELETE', url, null, axiosConfig);
  }
}
