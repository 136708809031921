import BaseService from './base.service';
import { useAuthStore } from '../store/authStore';

class SolidnetService extends BaseService {
  async login(data) {
    const { signIn } = useAuthStore();

    const loginResult = await this.dao.login(data);
    const { code, payload } = loginResult;
    if (code === 0) {
      signIn(payload);
      return true;
    }
    return false;
  }

  async checklogin() {
    const { signIn, signOut } = useAuthStore();
    try {
      const checkLoginResult = await this.dao.checklogin();
      const { code, payload } = checkLoginResult;
      if (code === 0) {
        signIn(payload);
      }
    } catch (ex) {
      signOut();
    }
  }

  async logout() {
    const { signOut } = useAuthStore();
    try {
      const logoutResult = await this.dao.logout();
      const { code, payload } = logoutResult;
      if (code === 0) {
        signOut();
      }
      return true;
    } catch (ex) {
      return false;
    }
  }

  async findCompany(query) {
    const companyResult = await this.dao.findCompany(query);
    const { code, payload } = companyResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async impersonationAdmin(data) {
    const { signIn } = useAuthStore();
    const impersonationResult = await this.dao.impersonationAdmin(data);
    const { code, payload } = impersonationResult;
    if (code === 0) {
      signIn(payload);
      return true;
    }
    return false;
  }

  async cancelImpersonationAdmin() {
    const { signIn } = useAuthStore();
    const cancelImpersonationResult = await this.dao.cancelImpersonationAdmin();
    const { code, payload } = cancelImpersonationResult;
    if (code === 0) {
      signIn(payload);
      return true;
    }
    return false;
  }

  async updateSetting(data) {
    const result = await this.dao.updateSetting(data);
    const { code, payload } = result;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getDetailCompany(companyId) {
    const detailCompanyResult = await this.dao.getDetailCompany(companyId);
    const { code, payload } = detailCompanyResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async updateCompany(companyId, dataUpdate) {
    const updateCompanyResult = await this.dao.updateCompany(companyId, dataUpdate);
    const { code } = updateCompanyResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async createCompany(dataCreate) {
    const createCompanyResult = await this.dao.createCompany(dataCreate);
    const { code } = createCompanyResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async findNotices(params) {
    const noticesListResult = await this.dao.findNotices(params);
    const { code, payload } = noticesListResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async getDetailNotice(noticeId) {
    const noticeDetailResult = await this.dao.findDetailNotice(noticeId);
    const { code, payload } = noticeDetailResult;
    if (code === 0) {
      return payload;
    }
    return null;
  }

  async deleteNotice(noticeId) {
    const deleteNoticeResult = await this.dao.deleteNotice(noticeId);
    const { code } = deleteNoticeResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async createNotice(params) {
    const createNoticeResult = await this.dao.createNotice(params);
    const { code } = createNoticeResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async updateNotice(noticeId, params) {
    const updateNoticeResult = await this.dao.updateNotice(noticeId, params);
    const { code } = updateNoticeResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async pushOnNotice(noticeId) {
    const pushNoticeResult = await this.dao.pushOnNotice(noticeId);
    const { code } = pushNoticeResult;
    if (code === 0) {
      return true;
    }
    return false;
  }

  async deleteCompany(companyId) {
    const deleteCompanyResult = await this.dao.deleteCompany(companyId);
    const { code } = deleteCompanyResult;
    if (code === 0) {
      return true;
    }
    return false;
  }
}

export default new SolidnetService('solidnet');
