<template>
  <q-layout v-if="loading" view="hHh LpR fff">
    <q-page-container>
      <q-page class="column items-center">
        <div class="col row items-center">
          <q-circular-progress indeterminate size="lg" color="light-blue" />
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
  <router-view v-else-if="authStore.isLoggedIn" v-slot="{ Component }">
    <transition name="fade">
      <div class="tw-grid tw-grid-cols-12">
        <div class="tw-col-start-2 tw-col-span-10">
          <component :is="Component" />
        </div>
      </div>
    </transition>
  </router-view>
  <ForgetPassword v-else-if="currentPath.includes('/admin/forgot-password')" />
    <LoginSolidnet v-else-if="currentPath.includes('/solidnet')" />
    <LoginAdmin v-else />
  <q-inner-loading :showing="isLoading || isLoadingAu">
    <q-spinner-pie size="50px" color="primary" />
  </q-inner-loading>
</template>
<script setup>
import {
  defineAsyncComponent, onMounted, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import SolidnetService from 'src/shared/services/solidnet.service';
import AdminService from 'src/shared/services/admin.service';
import { useAuthStore } from './shared/store/authStore';
import { useAppStore } from './shared/store/appStore';

const LoginAdmin = defineAsyncComponent(() => import('./pages/Auth/LoginAdmin'),
);
const LoginSolidnet = defineAsyncComponent(() => import('./pages/Auth/LoginSolidnet'),
);
const ForgetPassword = defineAsyncComponent(() => import('./pages/Password/ForgetPassword.vue'),
);
const route = useRouter();
const authStore = useAuthStore();
const { isLoading, isLoadingAu } = useAppStore();
const loading = ref(true);
const currentPath = ref('/');
const { currentRoute } = route;

watch(currentRoute, async (value) => {
  currentPath.value = value.path;
  if (currentPath.value.includes('/solidnet')) {
    if (
      currentPath.value.includes('/solidnet')
      && authStore.isImpersonation === true
    ) {
      route.push('/admin/users');
    } else {
      await SolidnetService.checklogin();
    }
  } else {
    await AdminService.checklogin();
  }
});
onMounted(async () => {
  // if (currentPath.value.includes('/solidnet')) {
  //   await SolidnetService.checklogin();
  // } else {
  //   await AdminService.checklogin();
  // }
  loading.value = false;
});
</script>
<style>
@import url("./assets/tailwind");
[v-cloak] {
  display: none;
}
</style>
