import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useAppStore = defineStore('appStore', () => {
  const loading = ref(false);
  const loadingAu = ref(false);
  const isShowBack = ref(false);
  const pathBack = ref({ path: '/' });
  const currentMenuName = ref('dashboards');
  const previousPath = ref({});
  const openModalSortConfirm = ref();
  const changeValue = ref(false);
  const processFileUpload = ref({});
  const xhrFileUpload = ref([]);

  const setLoading = (flag) => {
    loading.value = flag;
  };
  const setLoadingAu = (flag) => {
    loadingAu.value = flag;
  };

  const setShowBack = (flag) => {
    isShowBack.value = flag;
  };

  const setPathBack = (router) => {
    pathBack.value = router;
  };

  const setMenuName = (name) => {
    currentMenuName.value = name;
  };

  const setPreviousPath = (router) => {
    previousPath.value = router;
  };

  const setChangeValue = (value) => {
    changeValue.value = value;
  };

  const setOpenModalSortConfirm = (value) => {
    openModalSortConfirm.value = value;
  };

  const setProcessFileUpload = (index, value) => {
    processFileUpload.value[index] = value;
  };

  const setXhrFileUpload = (value) => {
    xhrFileUpload.value.push(value);
  };

  const clearUploadFile = () => {
    xhrFileUpload.value = [];
    processFileUpload.value = {};
  };

  const isLoading = computed(() => loading);
  const isLoadingAu = computed(() => loadingAu);
  const isChangeValue = computed(() => changeValue);
  const getProcessFileUpload = computed(() => processFileUpload.value);
  const getXhrFileUpload = computed(() => xhrFileUpload.value);

  return {
    isLoadingAu,
    isLoading,
    isShowBack,
    pathBack,
    currentMenuName,
    previousPath,
    isChangeValue,
    openModalSortConfirm,
    getProcessFileUpload,
    getXhrFileUpload,
    setShowBack,
    setPathBack,
    setLoading,
    setLoadingAu,
    setMenuName,
    setPreviousPath,
    setChangeValue,
    setOpenModalSortConfirm,
    setProcessFileUpload,
    setXhrFileUpload,
    clearUploadFile,
  };
});
